import { Route, BrowserRouter as Router, Routes } from 'react-router-dom'
import { ROUTES } from './common/constants'
import Landing from './components/Landing'
import Projects from './components/Projects'

export default function App() {
  return (
    <Router>
      <Routes>
        <Route path={ROUTES.ROOT} element={<Landing />} />
        <Route path={ROUTES.PROJECTS} element={<Projects />} />
      </Routes>
    </Router>
  )
}
