import { Stack } from '@mui/material'
import { styled } from '@mui/material/styles'
import React from 'react'
import { MAIL, PHONE } from '../common/constants'
import ContainerWrapper from './ContainerWrapper'
import Socials from './Socials'

const Footer = () => {
  return (
    <ContainerWrapper
      color='black'
      id='header'
      sx={{ paddingTop: '20px', paddingBottom: '20px', borderTop: '1px solid white', marginTop: '30px' }}
    >
      <Stack height='90px' flexDirection='row' alignItems='center' justifyContent='space-between'>
        <Stack flexDirection='row' gap='10px' flexWrap='wrap'>
          <Contact href={`mailto:${MAIL}`}>{MAIL}</Contact>
          <Contact href={`tel:${PHONE}`}>{PHONE}</Contact>
        </Stack>
        <Stack width='33%' flexDirection='row' gap='16px' alignItems='center' justifyContent='flex-end'>
          <Socials />
        </Stack>
      </Stack>
    </ContainerWrapper>
  )
}

export default React.memo(Footer)

const Contact = styled('a')(() => ({
  textDecoration: 'none',
  paddingRight: '15px'
}))
