import { Attachment } from 'airtable'
import useSWR from 'swr'
import { combineParams, fetcher, fieldsParams, spreadFields } from './requests'

export type TEvent = {
  id: string
  Name: string
  Date: string
  'Lenght (km)': number
  Flickr: string
  'Facebook event': string
  'Participants (P)': string[]
  'FB cover': Attachment[]
  Route: string
}

const params =
  combineParams({
    view: 'Chronological',
    filterByFormula: 'NOT({hideOnWeb})'
  }) +
  '&' +
  fieldsParams(['Name', 'Date', 'Lenght (km)', 'Flickr', 'Facebook event', 'Participants (P)', 'FB cover', 'Route'])
const key = `/Events${params}`

export default function useEvents() {
  const { data = [], isLoading } = useSWR(key, fetcher)
  const eventsData: TEvent[] = data.toReversed().map(spreadFields)

  return { eventsData, isLoading }
}
