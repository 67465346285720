import CssBaseline from '@mui/material/CssBaseline'
import { ThemeProvider } from '@mui/material/styles'
import { createRoot } from 'react-dom/client'
import App from './App'
import './app.css'
import theme from './theme'

const container = document.getElementById('root')
const root = createRoot(container as HTMLElement)

root.render(
  <>
    <ThemeProvider theme={theme}>
      <CssBaseline />
      <App />
    </ThemeProvider>
  </>
)
