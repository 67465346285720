import { styled } from '@mui/material/styles'
import React, { useEffect } from 'react'
import { getTypeColor, isTouchEvent, isTwoFingerTouch } from '../common/mapUtils'
import useGeoParser from '../hooks/useGeoParser'
import useMapbox from '../hooks/useMapbox'

const Map = ({ pageId, attachment }) => {
  const { filteredGeoJson } = useGeoParser(attachment)
  const { mapboxgl } = useMapbox()

  useEffect(() => {
    if (filteredGeoJson) {
      mapboxgl.accessToken = process.env.REACT_APP_MAPBOX_ACCESS_TOKEN
      const map = new mapboxgl.Map({
        container: pageId,
        style: 'mapbox://styles/matisszemturis/ckziywfda000514s4o1jmny4k',
        zoom: 3,
        center: [24.8, 56.85],
        scrollZoom: false
      })

      map.on('dragstart', (event) => {
        if (isTouchEvent(event) && !isTwoFingerTouch(event)) {
          map.dragPan.disable()
        }
      })

      // Drag events not emited after dragPan disabled, so I use touch event here
      map.on('touchstart', (event) => {
        if (isTouchEvent(event) && isTwoFingerTouch(event)) {
          map.dragPan.enable()
        }
      })

      map.on('load', () => {
        map.addControl(new mapboxgl.NavigationControl(), 'top-right')

        Object?.keys(filteredGeoJson)?.forEach((type, index) => {
          const geojson = {
            type: 'FeatureCollection',
            features: filteredGeoJson[type]
          }

          map.addSource(type, {
            type: 'geojson',
            data: geojson
          })

          map.addLayer({
            id: type,
            type: 'line',
            source: type,
            layout: {
              'line-join': 'round',
              'line-cap': 'round'
            },
            paint: {
              'line-color': getTypeColor(type),
              'line-width': 2
            }
          })
        })
      })
    }
  }, [filteredGeoJson])

  return <MapBox id={pageId} />
}

export default React.memo(Map)

const MapBox = styled('div')(() => ({
  position: 'relative',
  width: '100vw',
  maxHeight: '100vh',
  height: '600px',

  '.mapboxgl-ctrl-bottom-left': {
    opacity: 0,
    display: 'none'
  },
  '.mapboxgl-ctrl-attrib-inner': {
    opacity: 0,
    display: 'none'
  }
}))
