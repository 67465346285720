import { Box, CircularProgress } from '@mui/material'
import MsLogo from '../assets/images/ms_logo_transparent_white.png'

export default function AppLoader() {
  return (
    <Box
      sx={{
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
        width: '100vw',
        height: '100vh',
        position: 'relative'
      }}
    >
      <CircularProgress
        size={80}
        thickness={2}
        sx={{
          position: 'absolute',
          color: 'white'
        }}
      />

      <Box
        sx={{
          position: 'absolute',
          top: '50%',
          left: '50%',
          transform: 'translate(-50%, -50%)'
        }}
      >
        <img
          src={MsLogo}
          alt='Logo'
          width='32px'
          style={{
            borderRadius: '50%',
            objectFit: 'cover'
          }}
        />
      </Box>
    </Box>
  )
}
