import useSWR from 'swr'
import { combineParams, fetcher, fieldsParams, spreadFields } from './requests'
import { Attachment } from 'airtable'

const sortByEventCount = 'sort[0][field]=Sum events&sort[0][direction]=desc'
const params =
  combineParams({
    view: 'Grid view'
  }) +
  '&' +
  fieldsParams(['Name', 'Sum events', 'Photo']) +
  '&' +
  sortByEventCount

const key = `/People${params}`

export type TPerson = {
  id: string
  Name: string
  'Sum events': number
  Photo: Attachment[]
}

export default function usePeople() {
  const { data = [], isLoading } = useSWR(key, fetcher)
  const peopleData: TPerson[] = data.map(spreadFields)

  return { peopleData, isLoading }
}
