import { KeyboardArrowLeft, KeyboardArrowRight } from '@mui/icons-material'
import { Stack } from '@mui/material'
import { styled } from '@mui/material/styles'
import React, { useState } from 'react'
import useScreen from '../hooks/useScreen'
import Video from './Video'

export default function LandingVideo({ pageId, description, title, attachment = [] }) {
  const [selectedVideoIndex, setSelectedVideoIndex] = useState<number>(0)
  const { isMobile } = useScreen()
  const videoCount = attachment?.length
  const showToggles: boolean = videoCount > 3

  const handlePrevious = (event) => {
    const newSelectedIndex = selectedVideoIndex === 0 ? videoCount - 1 : selectedVideoIndex - 1
    onSelectVideo(event, newSelectedIndex)
  }

  const handleNext = (event) => {
    const newSelectedIndex = selectedVideoIndex === videoCount - 1 ? 0 : selectedVideoIndex + 1
    onSelectVideo(event, newSelectedIndex)
  }

  const onSelectVideo = (event: React.SyntheticEvent, index: number) => {
    event.stopPropagation()
    event.preventDefault()

    setSelectedVideoIndex(index)
  }

  const getPrevNextButton = (right?: boolean) => {
    if (!showToggles) {
      return true
    }

    const Icon = right ? KeyboardArrowRight : KeyboardArrowLeft
    const onClick = right ? handleNext : handlePrevious

    return (
      <Stack justifyContent='center' height='100%'>
        <Icon
          onClick={onClick}
          sx={{
            cursor: 'pointer',
            opacity: 0.8,
            '&:hover': {
              opacity: 1
            }
          }}
        />
      </Stack>
    )
  }

  const getVideoParams = (index: number) => {
    const isActive: boolean = index === selectedVideoIndex

    const getIsLabelShowing = () => {
      switch (true) {
        case isActive:
        case selectedVideoIndex + 1 === index:
        case selectedVideoIndex - 1 === index:
        case selectedVideoIndex === 0 && index < 3:
        case selectedVideoIndex === videoCount - 1 && index >= videoCount - 3:
          return true
        default:
          return false
      }
    }

    return { isActive, isLabelShowing: getIsLabelShowing() }
  }

  return (
    <Container id={pageId}>
      <VideoWrapper>
        {attachment.map(({ url }, index) => {
          const { isActive } = getVideoParams(index)
          return <Video url={url} key={url} sx={{ top: 0, left: 0, position: 'absolute', opacity: isActive ? 1 : 0 }} />
        })}
      </VideoWrapper>
      <OverflowingBox>
        <Title>{title}</Title>
        <Desc>{description}</Desc>
        <VideoButtons
          sx={{
            gridTemplateColumns: showToggles ? '25px 1fr 1fr 1fr 25px' : `repeat(${videoCount},1fr)`
          }}
        >
          {getPrevNextButton(false)}
          {attachment.map(({ filename }, index) => {
            const { isActive, isLabelShowing } = getVideoParams(index)
            const label: string = filename.slice(0, filename.indexOf('.'))

            if (isLabelShowing) {
              return (
                <HikeName
                  key={filename}
                  onClick={(event) => onSelectVideo(event, index)}
                  sx={{ opacity: isActive ? 1 : 0.6, fontSize: isMobile ? 12 : 16 }}
                >
                  {label}
                </HikeName>
              )
            }
          })}
          {getPrevNextButton(true)}
        </VideoButtons>
      </OverflowingBox>
    </Container>
  )
}

const Container = styled('div')(() => ({
  display: 'flex',
  flexDirection: 'column',
  textAlign: 'center',
  position: 'relative',
  backgroundColor: 'black',
  height: '1500px',
  maxHeight: '200vh'
}))

const VideoWrapper = styled('div')(() => ({
  position: 'sticky',
  display: 'block',
  top: '0px',
  maxHeight: '100vh',
  height: '100%',
  opacity: 0.9
}))

const VideoButtons = styled('span')(() => ({
  display: 'grid',
  width: '1000px',
  maxWidth: '100vw',
  margin: '96px 0px',
  padding: '0px 8px',
  gap: '5px',
  justifyItems: 'center',
  alignItems: 'start'
}))

const Title = React.memo(
  styled('div')(() => ({
    fontSize: '48px',
    lineHeight: '48px',
    fontWeight: 700,
    width: '448px',
    maxWidth: '90vw',
    textShadow: '0px 0px 2px #8a8a8a60'
  }))
)

const HikeName = styled('div')(() => ({
  fontWeight: '400',
  lineHeight: '20px',
  textAlign: 'center',
  cursor: 'pointer'
}))

const Desc = React.memo(
  styled('div')(() => ({
    fontSize: '24px',
    lineHeight: '32px',
    width: '448px',
    maxWidth: '90vw',
    textShadow: '0px 0px 2px #8a8a8a60'
  }))
)

const OverflowingBox = styled('div')(() => ({
  overflow: 'hidden',
  position: 'absolute',
  height: '100%',
  zIndex: 10,
  width: '100%',
  color: 'white',
  display: 'grid',
  justifyContent: 'center',
  gridTemplateRows: '1fr 1fr auto',
  alignItems: 'center',
  justifyItems: 'center'
}))
