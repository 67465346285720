import { Stack } from '@mui/material'
import { Pages } from '../common/constants'
import useEvents from '../swr/useEvents'
import usePartners from '../swr/usePartners'
import usePeople from '../swr/usePeople'
import useWebsite from '../swr/useWebsite'
import AppLoader from './AppLoader'
import Events from './Events'
import Footer from './Footer'
import Header from './Header'
import Hikers from './Hikers'
import LandingVideo from './LandingVideo'
import Map from './Map'
import Partners from './Partners'
import Phylosophy from './Phylosophy'

export default function Landing() {
  const { sectionsData = [], isLoading: isLoadingSections } = useWebsite()
  const { eventsData = [], isLoading: isLoadingEvents } = useEvents()
  const { partnersData = [], isLoading: isLoadingPartners } = usePartners()
  const { peopleData = [], isLoading: isLoadingPeople } = usePeople()
  const loading = isLoadingSections || isLoadingEvents || isLoadingPartners || isLoadingPeople

  const getPage = ({ pageId, title, description, attachment }) => {
    const key = pageId

    switch (pageId) {
      case Pages.LANDING:
        return <LandingVideo {...{ pageId, title, description, attachment, key }} />
      case Pages.HIKERS:
        return <Hikers {...{ pageId, title, description, key }} people={peopleData} />
      case Pages.PHILOSOPHY:
        return <Phylosophy {...{ pageId, title, description, key }} people={peopleData} events={eventsData} />
      case Pages.PARTNERS:
        return <Partners {...{ pageId, title, description, key }} partners={partnersData} />
      case Pages.HIKES:
        return <Events {...{ pageId, title, description, key }} events={eventsData} sections={sectionsData} />
      case Pages.MAP:
        return <Map {...{ pageId, attachment, key }} />
    }
  }

  if (loading) {
    return <AppLoader />
  }

  return (
    <Stack>
      <Header />
      {sectionsData.map(getPage)}
      <Footer />
    </Stack>
  )
}
