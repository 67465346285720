import useSWR from 'swr'
import { combineParams, fetcher, fieldsParams, spreadFields } from './requests'

const params =
  combineParams({
    filterByFormula: 'isPublic'
  }) +
  '&' +
  fieldsParams(['pageId', 'order', 'title', 'description', 'attachment'])

const key = `/Website${params}`

export default function useWebsite() {
  const { data = [], isLoading } = useSWR(key, fetcher)
  const sectionsData = data.map(spreadFields).sort((a, b) => a.order - b.order)

  return { sectionsData, isLoading }
}
