import { Stack } from '@mui/material'
import { styled } from '@mui/material/styles'
import React, { useMemo } from 'react'
import { transientProps } from '../common/utils'
import useScreen from '../hooks/useScreen'
import ContainerWrapper from './ContainerWrapper'
import Description from './Description'
import { TEvent } from '../swr/useEvents'
import { TPerson } from '../swr/usePeople'

const Phylosophy = ({
  pageId,
  title,
  description,
  events,
  people
}: {
  pageId: string
  title: string
  description: string
  events: TEvent[]
  people: TPerson[]
}) => {
  const { isDesktop } = useScreen()

  const { peopleAttended, kilometers } = useMemo(() => {
    const peopleAttended: number = events.reduce((sum, event) => {
      const participants: number = event?.['Participants (P)']?.length || 0
      return sum + participants
    }, 0)

    const kilometers: number = events.reduce((sum, event) => {
      const eventLength: number = event?.['Lenght (km)'] || 0
      return sum + eventLength
    }, 0)

    return { peopleAttended, kilometers }
  }, [events])

  const members: number = useMemo(() => people?.filter((person) => person['Sum events'] > 2)?.length, [people])

  const STATS = [
    {
      value: events?.length,
      label: 'events organised'
    },
    {
      value: peopleAttended,
      label: 'people joined'
    },
    {
      value: kilometers,
      label: 'kilometers explored'
    },
    {
      value: members,
      label: 'team members'
    }
  ]

  return (
    <ContainerWrapper color='white' title={title} id={pageId}>
      <Stack flexDirection='row' justifyContent='space-between' flexWrap={isDesktop ? 'nowrap' : 'wrap'} gap='40px'>
        <Description>{description}</Description>
        <StatsContainer>
          {STATS.map(({ label, value }, index) => (
            <StatsItem key={index}>
              <ValueBox>{Math.ceil(value).toLocaleString('fr-FR')}</ValueBox>
              <LabelBox>{label.replaceAll(' ', '\n')}</LabelBox>
            </StatsItem>
          ))}
        </StatsContainer>
      </Stack>
    </ContainerWrapper>
  )
}

export default React.memo(Phylosophy)

const StatsContainer = styled(
  'div',
  transientProps
)(() => ({
  display: 'grid',
  backgroundColor: '#F4F4F4',
  padding: '16px',
  borderRadius: '4px',
  maxWidth: '100%',
  width: 'unset',
  gridTemplateColumns: `repeat(2, auto)`
}))

const StatsItem = styled('div')(() => ({
  display: 'flex',
  flexDirection: 'column',
  justifyContent: 'center',
  alignItems: 'center',
  height: '115px',
  width: '160px',
  maxWidth: 'calc((100vw - 72px)/2)',
  gap: '8px'
}))

const ValueBox = styled('div')(() => ({
  fontSize: '36px',
  lineHeight: '41px',
  fontWeight: 500,
  letterSpacing: '0px',
  textAlign: 'center'
}))

const LabelBox = styled('div')(() => ({
  fontSize: '16px',
  lineHeight: '18px',
  fontWeight: 500,
  letterSpacing: '0px',
  textAlign: 'center',
  wordBreak: 'break-all',
  whiteSpace: 'pre-line',
  opacity: 0.6
}))
