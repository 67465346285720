import { styled } from '@mui/material/styles'
import { HikerStyle } from '../common/constants'
import { transientProps } from '../common/utils'
import useScreen from '../hooks/useScreen'
import { TPerson } from '../swr/usePeople'
import MS_LOGO from './../assets/images/ms_logo_white.jpg'

export default function HikersList({ people }: { people: TPerson[] }) {
  const { isMobile, isTablet } = useScreen()
  const isSmallScreen = isMobile || isTablet

  return people
    .filter(({ Photo }) => !!Photo)
    .slice(0, 96)
    .map((person) => {
      const photo = person?.Photo[0]?.thumbnails?.large?.url
      return (
        <Hiker key={person.id}>
          <Photo loading='lazy' $isSmallScreen={isSmallScreen} alt='person' src={photo || MS_LOGO} />
          <div>
            <Name $isSmallScreen={isSmallScreen}>{person.Name}</Name>
            <HikeCount $isSmallScreen={isSmallScreen}>{person['Sum events']} events</HikeCount>
          </div>
        </Hiker>
      )
    })
}

const Hiker = styled('div')(() => ({
  display: 'flex',
  flexDirection: 'row',
  alignItems: 'center',
  height: HikerStyle.HIKER_CARD_HEIGHT
}))

const Photo = styled(
  'img',
  transientProps
)(({ $isSmallScreen }: { $isSmallScreen: boolean }) => ({
  width: $isSmallScreen ? '48px' : '64px',
  height: $isSmallScreen ? '48px' : '64px',
  borderRadius: '50px'
}))

const Name = styled(
  'div',
  transientProps
)(({ $isSmallScreen }: { $isSmallScreen: boolean }) => ({
  fontWeight: 500,
  fontSize: $isSmallScreen ? '14px' : '18px',
  lineHeight: '23px'
}))

const HikeCount = styled(
  'div',
  transientProps
)(({ $isSmallScreen }: { $isSmallScreen: boolean }) => ({
  fontWeight: 400,
  fontSize: $isSmallScreen ? '12px' : '16px',
  lineHeight: '24px',
  opacity: 0.6
}))
