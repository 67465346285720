import { Link, Stack } from '@mui/material'
import { styled } from '@mui/material/styles'
import moment from 'moment'
import { convertYYMMDD } from '../common/date'
import useScreen from '../hooks/useScreen'
import { TEvent } from '../swr/useEvents'

export default function Event(event: TEvent) {
  const { isMobile, isTablet } = useScreen()

  const UNIT = 19
  const width = (isMobile ? 10 : isTablet ? 15 : 20) * UNIT
  const height = (width / UNIT) * 25

  const isInFuture = moment(event.Date).isAfter(moment())
  const cover = event?.['FB cover']?.[0]?.thumbnails.large.url

  return (
    <EventBox
      id={event.id}
      href={isInFuture ? event['Facebook event'] : event['Flickr']}
      target='_blank'
      sx={{ backgroundImage: `url(${cover})`, width, height, minWidth: width }}
    >
      <CardWrapper>
        <HikeName>{event.Name}</HikeName>
        <Stack flexDirection='row' justifyContent='space-between' alignItems='flex-end'>
          <div>
            {event.Route}
            <br />
            {convertYYMMDD(event.Date)}
          </div>
          {isInFuture && <Upcoming>Upcoming</Upcoming>}
        </Stack>
      </CardWrapper>
    </EventBox>
  )
}

const EventBox = styled(Link)(() => ({
  borderRadius: '4px',
  backgroundSize: 'cover',
  backgroundRepeat: 'no-repeat',
  backgroundPosition: 'center center',

  boxShadow: 'inset 0 0 80px 80px #00000020'
}))

const CardWrapper = styled('div')(() => ({
  display: 'flex',
  flexDirection: 'column',
  padding: '10px',
  justifyContent: 'space-between',
  color: 'white',
  textShadow: '0px 0px 2px #8a8a8a60',
  fontSize: '14px',
  fontWeight: 500,
  height: '100%',
  borderRadius: '4px',
  transitionDuration: '75ms',

  '&:hover': {
    backdropFilter: 'blur(24px) brightness(80%) !important'
    // transitionTimingFunction: 'ease-in'
  }
}))

const HikeName = styled('div')(() => ({
  fontSize: '20px',
  fontWeight: 600,
  lineHeight: '23px',
  letterSpacing: '0em',
  textAlign: 'left'
}))

const Upcoming = styled('div')(() => ({
  borderRadius: '20px',
  padding: '2px 8px',
  backgroundColor: 'green',
  width: 'fit-content',
  height: 'fit-content',
  fontSize: '10px',
  margin: '4px 0px'
}))
