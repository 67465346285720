import useSWR from 'swr'
import { combineParams, fetcher, fieldsParams, spreadFields } from './requests'
import { Attachment } from 'airtable'

const params =
  combineParams({
    filterByFormula: 'isPublic'
  }) +
  '&' +
  fieldsParams([
    'Project Acronym',
    'Project Title Full',
    'Project objective',
    'Project results',
    'Project budget information',
    'Project implementation',
    'Images',
    'BottomImages'
  ])

const key = `/Projects${params}`

export type TProject = {
  id: string
  'Project Acronym': string
  'Project Title Full': string
  'Project objective': string
  'Project results': string
  'Project budget information': string
  'Project implementation': string
  Images: Attachment[]
  BottomImages: Attachment[]
}

export default function useProjects() {
  const { data = [], isLoading } = useSWR(key, fetcher)
  const parsed: TProject[] = data.map(spreadFields)

  return { projectsData: parsed, isLoading }
}
