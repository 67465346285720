import { styled } from '@mui/material/styles'
import React, { useState } from 'react'
import { HikerStyle } from '../common/constants'
import { transientProps } from '../common/utils'
import useScreen from '../hooks/useScreen'
import ContainerWrapper from './ContainerWrapper'
import Description from './Description'
import HikersList from './HikersList'
import ShowMoreButton from './ShowMore.Button'
import { TPerson } from '../swr/usePeople'

export default function Hikers({
  pageId,
  title,
  description,
  people
}: {
  pageId: string
  title: string
  description: string
  people: TPerson[]
}) {
  const { isMobile, isTablet, isDesktop, isLaptop } = useScreen()
  const [isShowingAll, setShowAll] = useState(false)
  const isSmallScreen = isMobile || isTablet

  const hikerColumns = isDesktop ? 4 : isLaptop ? 3 : 2

  const toggleShowAll = (event: React.SyntheticEvent) => {
    event.preventDefault()
    event.stopPropagation()

    setShowAll((pS) => !pS)
  }

  return (
    <ContainerWrapper color='white' title={title} id={pageId}>
      <Description>{description}</Description>
      <HikersBox
        $isSmallScreen={isSmallScreen}
        sx={{
          gridTemplateColumns: `repeat(${hikerColumns}, minmax(0, 1fr))`,
          maxHeight: isShowingAll
            ? 'unset'
            : HikerStyle.VISIBLE_HIKER_ROWS * (HikerStyle.HIKER_CARD_HEIGHT + HikerStyle.ROW_GAP)
        }}
        id='enable-scroll'
      >
        <HikersList people={people} />
      </HikersBox>

      <ShowMoreButton isShowingMore={isShowingAll} onClick={toggleShowAll} sx={{ marginTop: '16px' }} />
    </ContainerWrapper>
  )
}

const HikersBox = styled(
  'div',
  transientProps
)(({ $isSmallScreen }: { $isSmallScreen: boolean }) => ({
  width: '100%',
  display: 'grid',
  justifyContent: 'space-between',
  rowGap: HikerStyle.ROW_GAP,
  overflow: 'hidden',

  '& > div': {
    gap: $isSmallScreen ? '8px' : '16px'
  }
}))
