import { Accordion, AccordionDetails, AccordionSummary, Stack, styled } from '@mui/material'
import Markdown from 'react-markdown'
import useProjects from '../swr/useProjects'
import AppLoader from './AppLoader'
import ContainerWrapper from './ContainerWrapper'
import Footer from './Footer'
import Header from './Header'

export default function Projects() {
  const { projectsData = [], isLoading } = useProjects()

  if (isLoading) {
    return <AppLoader />
  }

  return (
    <>
      <Header />
      <ContainerWrapper
        color='black'
        title=''
        id='Projects'
        sx={{ position: 'relative', minHeight: 'calc(100vh - 200px)' }}
      >
        <Stack
          direction='column'
          alignItems='center'
          height='100%'
          id='container'
          width='100%'
          style={{ backgroundColor: 'black', color: 'white' }}
        >
          <Title style={{ fontSize: 36 }}>Projects</Title>
          {projectsData.map((project, index) => {
            return (
              <Accordion
                key={index}
                defaultExpanded
                expanded={true}
                style={{ backgroundColor: 'unset', color: 'white', maxWidth: 900 }}
              >
                <AccordionSummary expandIcon={null}>
                  <Title style={{ fontSize: 22 }}>{project['Project Title Full']}</Title>
                </AccordionSummary>
                {project.Images?.map(({ url }, index) => (
                  <img alt={`project-top-${index}`} key={index} width='100%' src={url} />
                ))}
                <AccordionDetails>
                  <Stack gap='1rem' key={index}>
                    <Title>Project objective</Title>
                    <MarkdownText>{project['Project objective']}</MarkdownText>
                    <Title>Project results</Title>
                    <MarkdownText>{project['Project results']}</MarkdownText>
                    <Title>Project budget information</Title>
                    <MarkdownText>{project['Project implementation']}</MarkdownText>
                    <Title>Project implementation</Title>
                    <MarkdownText>{project['Project implementation']}</MarkdownText>
                    {project.BottomImages.map(({ url }, index) => (
                      <img alt={`project-bottom-${index}`} key={index} width='100%' src={url} />
                    ))}
                  </Stack>
                </AccordionDetails>
              </Accordion>
            )
          })}
        </Stack>
      </ContainerWrapper>
      <Footer />
    </>
  )
}

const MarkdownText = ({ children }: { children: string }) => {
  return (
    <Text>
      <Markdown>{children}</Markdown>
    </Text>
  )
}

const Title = styled('div')(() => ({
  fontStyle: 'normal',
  fontWeight: 700,
  fontSize: '20px',
  lineHeight: '30px',
  fontFamily: 'Work Sans'
}))

const Text = styled('div')(() => ({
  fontStyle: 'normal',
  fontWeight: 400,
  fontSize: '14px',
  lineHeight: '22px',
  maxWidth: '100%',
  opacity: 0.8,
  fontFamily: 'Work Sans',
  textAlign: 'justify',

  '& p': {
    margin: 0,
    padding: 0
  },

  '& ol': {
    margin: 0,
    paddingInlineStart: 20
  },

  '& a': {
    textDecoration: 'none',
    borderBottom: '1px solid',
    '&:hover': {
      borderBottom: '1px solid #00000000'
    }
  }
}))
