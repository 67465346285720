import { styled } from '@mui/material/styles'
import React, { useMemo } from 'react'
import { transientProps } from '../common/utils'
import useScreen from '../hooks/useScreen'

interface IProps {
  color: keyof typeof CONTAINER_COLOR
  children: JSX.Element | JSX.Element[]
  title?: string
  id: string
  sx?: any
}

const CONTAINER_COLOR = {
  black: '#000000',
  white: '#ffffff',
  gray: '#f6f6f6'
}

const ContainerWrapper = ({ color, children, title, id, sx = {} }: IProps) => {
  const fontColor: string = color === 'black' ? 'white' : 'black'
  const { isTablet, isLaptop, isDesktop } = useScreen()

  const padding = useMemo(() => {
    if (isDesktop) {
      return '128px 96px'
    }

    if (isLaptop) {
      return '96px 96px'
    }

    if (isTablet) {
      return '48px 48px'
    }

    return '32px 16px'
  }, [isTablet, isLaptop, isDesktop])

  return (
    <Container sx={{ backgroundColor: CONTAINER_COLOR[color], color: fontColor }} id={id}>
      <Box sx={{ padding, ...sx }}>
        {title && <Title>{title}</Title>}
        {children}
      </Box>
    </Container>
  )
}

export default React.memo(ContainerWrapper)

const Container = styled('div')(() => ({
  width: '100vw',
  display: 'flex',
  flexDirection: 'column',
  alignItems: 'center',
  overflowX: 'hidden'
}))

const Box = styled(
  'div',
  transientProps
)(() => ({
  width: '1440px',
  maxWidth: '100vw',
  display: 'flex',
  flexDirection: 'column'
}))

const Title = React.memo(
  styled('div')(({ theme }) => ({
    fontFamily: 'Work Sans',
    fontStyle: 'normal',
    fontWeight: 700,
    fontSize: '36px',
    lineHeight: '41px'
  }))
)
