const URL = `https://api.airtable.com/v0/appWPfi8rc24FiRPv`

export const fetcher = async (apiPath) => {
  let allRecords = []
  let offset = null

  try {
    do {
      // Construct the API URL with offset if it exists
      const apiUrl = offset ? `${URL}${apiPath}&offset=${offset}` : `${URL}${apiPath}`

      // Fetch the data
      const response = await fetch(apiUrl, {
        headers: {
          Authorization: `Bearer ${process.env.REACT_APP_AIRTABLE_API}`
        }
      })

      if (!response.ok) {
        throw new Error(`HTTP error! status: ${response.status}`)
      }

      const data = await response.json()

      // Add the records from this page to the allRecords array
      allRecords = allRecords.concat(data.records)

      // Update offset for the next page (if it exists)
      offset = data.offset
    } while (offset) // Continue if there's an offset (more pages)

    return allRecords
  } catch (error) {
    console.error('Error fetching Airtable records:', error)
    throw error
  }
}
export const combineParams = (obj: Record<string, any>) => {
  if (!obj) return ''

  return Object.keys(obj).reduce((prev, current) => {
    const value = obj?.[current]
    if (value === null || value === undefined) return prev
    const param = `${current}=${encodeURIComponent(value)}`
    return prev ? `${prev}&${param}` : `?${param}`
  }, '')
}

export function fieldsParams(fields: string[]) {
  return fields.map((field) => `fields[]=${encodeURIComponent(field.trim())}`).join('&')
}

export const spreadFields = ({ id, fields }) => ({
  id,
  ...fields
})
