import { styled } from '@mui/material/styles'
import React from 'react'
import useScreen from '../hooks/useScreen'
import { TPartner } from '../swr/usePartners'
import ContainerWrapper from './ContainerWrapper'
import Description from './Description'

const Partners = ({
  pageId,
  title,
  description,
  partners
}: {
  pageId: string
  title: string
  description: string
  partners: TPartner[]
}) => {
  const { isLaptop, isDesktop } = useScreen()
  const columns = isDesktop ? 5 : isLaptop ? 4 : 3

  return (
    <ContainerWrapper color='black' title={title} id={pageId} sx={{}}>
      <Description>{description}</Description>
      <PatnersGrid
        sx={{
          gridTemplateColumns: `repeat(${columns}, minmax(0, 1fr))`
        }}
      >
        {partners.map((partner) => {
          const { Logo, Name, URL, Facebook, Instagram } = partner
          const logoUrl = Logo?.[0]?.url
          if (!logoUrl || !Name) return null
          return (
            <Partner
              key={Name}
              href={URL ?? Facebook ?? Instagram}
              target='_blank'
              sx={{ backgroundImage: `url(${logoUrl})` }}
            />
          )
        })}
      </PatnersGrid>
    </ContainerWrapper>
  )
}

export default React.memo(Partners)

const PatnersGrid = styled('div')(() => ({
  width: '100%',
  display: 'grid',
  justifyContent: 'space-between',
  gap: '16px'
}))

const Partner = styled('a')(() => ({
  backgroundColor: 'black',
  borderRadius: '4px',
  width: '100%',
  height: '90px',
  cursor: 'pointer',
  backgroundRepeat: 'no-repeat',
  backgroundSize: 'contain',
  backgroundPosition: 'center',
  transitionDuration: '0.3s',

  '&:hover': {
    transform: 'scale(1.1)'
  }
}))
