import { Attachment } from 'airtable'
import useSWR from 'swr'
import { combineParams, fetcher, fieldsParams, spreadFields } from './requests'

const params =
  combineParams({
    filterByFormula: 'NOT({Show on website})=""'
  }) +
  '&' +
  fieldsParams(['Name', 'URL', 'Logo', 'Facebook', 'Instagram'])

const key = `/Partners${params}`

export type TPartner = {
  id: string
  Name: string
  URL: string
  Logo: Attachment[]
  Facebook: string
  Instagram: string
}

export default function usePartners() {
  const { data = [], isLoading } = useSWR(key, fetcher)
  const partnersData: TPartner[] = data.map(spreadFields)

  return { partnersData, isLoading }
}
