import { Stack } from '@mui/material'
import { styled } from '@mui/material/styles'
import { FB_URL, Pages, YT_URL } from '../common/constants'
import { ObjectValues } from '../common/interfaces'
import useScreen from '../hooks/useScreen'
import ContainerWrapper from './ContainerWrapper'
import Description from './Description'
import Event from './Event'
import EventsFooter from './EventsFooter'

export default function Events({ pageId, title, description, events, sections }) {
  const { isMobile, isTablet } = useScreen()

  const getEventFooter = ({ pageId, title, description }) => {
    const key = pageId

    switch (pageId as ObjectValues<typeof Pages>) {
      case Pages.ADVENTURES:
        return (
          <EventsFooter
            {...{
              key,
              pageId,
              title,
              description,
              link: {
                href: `${FB_URL}/events`,
                label: 'Upcoming events on Facebook'
              }
            }}
          />
        )
      case Pages.MORE_HIKES:
        return (
          <EventsFooter
            {...{
              key,
              pageId,
              title,
              description,
              link: {
                href: YT_URL,
                label: 'Visit our YouTube channel'
              }
            }}
          />
        )
    }
  }

  return (
    <ContainerWrapper color='black' title={title} id={pageId} sx={{ position: 'relative' }}>
      <Description>{description}</Description>
      <EventListWrapper>
        <EventList id='enable-scroll'>
          {events.map((event, index) => (
            <Event key={index} {...event} />
          ))}
        </EventList>
      </EventListWrapper>
      <Stack
        justifyContent='space-between'
        width='100%'
        flexDirection={isMobile || isTablet ? 'column' : 'row'}
        gap={isMobile || isTablet ? '4rem' : '6rem'}
      >
        {sections.map(getEventFooter)}
      </Stack>
    </ContainerWrapper>
  )
}

const EventListWrapper = styled('div')(() => ({
  position: 'relative',
  width: '100vw',
  maxWidth: '100vw',
  left: 'calc(-50vw + 50%)',
  padding: '0px 16px'
}))

const EventList = styled('div')(() => ({
  width: '100%',
  display: 'flex',
  gap: '16px',
  flexDirection: 'row',
  paddingBottom: '96px',
  marginBottom: '96px',
  overflowX: 'auto'
}))
